import React, { useState, useEffect } from "react";
import "./Home.css";
import backg from "../assets/backg.webp";
import Navbar from "../common/Navbar";
import arow from "../assets/arow.webp";
import CustomDropdown from "../hero/CustomDropdown";

const Home = () => {
  const [showPara, setShowPara] = useState(1);

  useEffect(() => {
    const interval = setInterval(() => {
      setShowPara((prev) => (prev < 4 ? prev + 1 : 1));
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div style={{ backgroundColor: "#ededed", position: "relative" }}>
      <Navbar />
      <div className="relative background-container">
        <img
          className="h-96 w-full object-cover backimg"
          src={backg}
          alt="Background"
        />
        <div className="absolute top-[80%] lf left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-center">
          <div className="flex flex-col md:flex-row gap-4 items-center justify-center mb-4">
            <div className="relative ss bg-white rounded-[0.8rem] p-1 flex gap-4 given md:gap-12 w-full md:w-[624px]  ">
              <input
                type="text"
                className="p-2 border  border-gray-300 rounded-l rounded-r search-input lg:w-full w-full  border-none focus:outline-none border-transparent focus:border-transparent focus:ring-0 pl-8 text-sm font-normal"
                placeholder="Search By, City, Location, Project, Developer"
              />
              <button className="absolute right-2 top-1/2 transform -translate-y-1/2 md:hidden bg-[#F2833E] text-white text-base p-2 rounded-full search-button focus:outline-none border-transparent focus:border-transparent focus:ring-0">
                <img src={arow} alt="Search Icon" style={{ height: "16px" }} />
              </button>
              <span className="border border-l border-orange-500 m-1 border-element lg:hidden"></span>
              <span className="border border-l border-orange-500 m-1 border-element hidden lg:inline-block lg:absolute lg:top-[4px] lg:bottom-[4px] lg:left-[430px]  2xl:left-[74%]"></span>
              <CustomDropdown />
            </div>
            <button className="searcb  icing fon hidden md:flex items-center gap-2 bg-[#F2833E] text-white text-base px-4 py-2 rounded-lg search-button focus:outline-none border-transparent focus:border-transparent focus:ring-0">
              <img className="iconhi index" src={arow} alt="Search Icon" />
              Search
            </button>
          </div>
        </div>
      </div>
      {showPara === 1 && (
        <p className={`para ${showPara === 1 ? "fade-in-text" : "fade-out"}`}>
          <span className="bold" style={{ color: "#f2833e" }}>
            0%{" "}
          </span>
          <span className="bold" style={{ color: "#766F6F" }}>
            Brokerage,
          </span>{" "}
          <span className="bold" style={{ color: "#f2833e" }}>
            100%{" "}
          </span>
          <span className="bold" style={{ color: "#766F6F" }}>
            Dream Home{" "}
          </span>{" "}
          <span style={{ color: "#766F6F" }}>Your Journey, Our Priority.</span>
        </p>
      )}
      {showPara === 2 && (
        <p className={`para ${showPara !== 1 ? "fade-in-text" : "fade-out"}`}>
          <span className="bold" style={{ color: "#f2833e" }}>
            24/7{" "}
          </span>
          <span className="bold" style={{ color: "#766F6F" }}>
            Support
          </span>{" "}
          <span className="bold" style={{ color: "#f2833e" }}>
            -{" "}
          </span>
          <span className="bold" style={{ color: "#766F6F" }}>
            Your Convenience,{" "}
          </span>{" "}
          <span style={{ color: "#766F6F" }}> Our Commitment</span>
        </p>
      )}
      {showPara === 3 && (
        <p className={`para ${showPara !== 1 ? "fade-in-text" : "fade-out"}`}>
          <span className="bold" style={{ color: "#f2833e" }}>
            98%{" "}
          </span>
          <span className="bold" style={{ color: "#766F6F" }}>
            Customer
          </span>{" "}
          <span className="bold" style={{ color: "#f2833e" }}>
            Satisfaction Rate{" "}
          </span>
          <span className="bold" style={{ color: "#766F6F" }}>
            – Where Dreams{" "}
          </span>{" "}
          <span style={{ color: "#766F6F" }}> Meet Reality</span>
        </p>
      )}
      {showPara === 4 && (
        <p className={`para ${showPara !== 1 ? "fade-in-text" : "fade-out"}`}>
          <span className="bold" style={{ color: "#f2833e" }}>
            Elevate Your{" "}
          </span>
          <span className="bold" style={{ color: "#766F6F" }}>
            Lifestyle
          </span>{" "}
          <span className="bold" style={{ color: "#766F6F" }}>
            with{" "}
          </span>
          <span className="bold" style={{ color: "#f2833e" }}>
            Trending{" "}
          </span>{" "}
          <span style={{ color: "#766F6F" }}> Projects</span>
        </p>
      )}
    </div>
  );
};

export default Home;
