import React from 'react';
import Layout from './components/common/Layout';
import AllHomeComponents from './components/hero/AllHomeComponents';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import AllAboutComponents from './components/about/AllAboutComponents';

function App() {
  return (
<Router>
  <div>
    <Layout>
      <Routes>
      <Route path='/' element={<AllHomeComponents/>}/>
      <Route path='/about' element={<AllAboutComponents/>}/>
      </Routes>
    </Layout>
  </div>
</Router>
  );
}

export default App;
