import React from 'react';
import './Achivments.css'; 
import saheel from '../assets/saheel.webp';
import VTP1 from '../assets/VTP1.webp';
import runwal from '../assets/runwal.webp';

const Achivments = () => {
  return (
  <div>
      <h2 className="text-2xl daschi achi md:text-4xl Atopb  font-semibold text-gray-600 text-center mt-10 md:mt-20">
        <span style={{ color: '#B6ADAD' }}>Unveiling Our </span>
        <span style={{ color: '#F2833E' }}>Brick </span>
        <span style={{ color: '#B6ADAD' }}> by</span>
        <span style={{ color: '#F2833E' }}> Brick </span>
        <span className='mar' style={{ color: '#B6ADAD' }}>Achievements</span>
      </h2>
    <div className="achivments-container">
      <div className="gray-square">
          <img src={saheel} alt="Saheel Logo" className="logo-img" />
          <p className="text">Best Perfomance</p>
      </div>
      <div className="gray-square">
          <img src={VTP1} alt="VTP Logo" className="logo-img" />
          <p className="text">The Game Changer</p>
          <p className="text">Of the Year 2022</p>
      </div>
      <div className="gray-square">
          <img src={runwal} alt="Runwal Logo" className="logo-img" />
          <p className="text">Platinuim Star</p>
      </div>
      <div className="gray-square">
          <img src={saheel} alt="Saheel Logo" className="logo-img" />
          <p className="text">Top Perfomer</p>
          <p className="text">(Residential)</p>
      </div>
    </div>
  </div>
  );
};

export default Achivments;
