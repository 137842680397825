import React, { useState, useEffect } from "react";
import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";
import "./Counter.css";
import money from "../assets/money.webp";
import prop1 from "../assets/prop1.webp";
import hand1 from "../assets/hand1.webp";
import build from "../assets/build.webp";
import { Parallax } from "react-parallax";
import paralax from "../assets/paralax.webp";

const Counter = () => {
  const [isVisible, setIsVisible] = useState(false);

  const onVisibilityChange = (visible) => {
    if (visible) {
      setIsVisible(true);
    }
  };

  useEffect(() => {
    document.addEventListener("scroll", function () {
      if (window.scrollY > 0) {
        document.body.classList.add("scrolled");
      } else {
        document.body.classList.remove("scrolled");
      }
    });
  }, []);

  return (
    <div className="counter-con">
      <div>
        <Parallax className="custom-parallax" bgImage={paralax} strength={200}>
          <div className="overlay"></div>
          <VisibilitySensor onChange={onVisibilityChange}>
            <div className="counters-container">
              <div class="counter-background"></div>
              <div>
                <img className="counter-icon comp " src={build} alt="" />
              </div>
              <div className="counter-item">
                {isVisible && (
                  <CountUp
                    className="countclass"
                    start={0}
                    end={100}
                    duration={2}
                    suffix="+"
                    separator=","
                  />
                )}
                <div className="counter-label">Remarkable Properties</div>
              </div>
              <div>
                <img className="counter-icon comp " src={hand1} />
              </div>
              <div className="counter-item">
                {isVisible && (
                  <CountUp
                    className="countclass"
                    start={0}
                    end={90000}
                    duration={2}
                    suffix="+"
                    separator=","
                  />
                )}
                <div className="counter-label">Ecstatic Customers</div>
              </div>
              <div>
                <img className="counter-icon comp " src={money} />
              </div>
              <div className="counter-item">
                {isVisible && (
                  <CountUp
                    className="countclass"
                    start={0}
                    end={5000}
                    duration={2}
                    prefix="₹"
                    suffix="+"
                  />
                )}
                <div className="counter-label">Crores in Property Sales</div>
              </div>
              <div>
                <img className="counter-icon comp " src={prop1} />
              </div>
              <div className="counter-item">
                {isVisible && (
                  <CountUp
                    className="countclass"
                    start={0}
                    end={200}
                    duration={2}
                    suffix="+"
                  />
                )}
                <div className="counter-label">Seasoned Property Experts</div>
              </div>
            </div>
          </VisibilitySensor>
        </Parallax>
      </div>
    </div>
  );
};

export default Counter;
