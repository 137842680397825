import React from 'react'
import Try from './Try'
import Second from './Second'

const AllAboutComponents = () => {
  return (
    <div>
      <Try/>
      <Second/>
    </div>
  )
}

export default AllAboutComponents
