import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import lodha1 from "../assets/lodha1.webp";
import manrea from "../assets/manrea.webp";
import godrej1 from "../assets/godrej1.webp";
import vtp from "../assets/vtp.webp";
import ko from "../assets/ko.webp";
import lux1 from "../assets/lux1.webp";
import sahill from "../assets/sahill.webp";
import sha from "../assets/sha.webp";
import "./Ldemo.css";

const Logo = () => {
  const isMobileView = window.innerWidth <= 768;

  const settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    speed: 4000,
    autoplaySpeed: 4000,
    pauseOnHover: false,
    cssEase: "linear",
    rtl: true,
    centerPadding: "0px",
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
        },
      },
    ],
  };

  return (
    <div className="logos carousel-container">
      <Slider {...settings}>
        <div className=" ">
          <div className="flex  gap-6 ">
            <img
              className="h-[90px] w-fit heightof sethi"
              src={lodha1}
              alt="1"
            />
            {/* <img className="h-[85px] w-fit mx-6 "  src={vtp} alt="1" /> */}
          </div>
        </div>

        <div className=" ">
          <div className="flex  gap-6  ">
            <img
              className="h-[95px] w-fit ml-12 sideing heightof sethi vtp"
              src={vtp}
              alt="2"
            />
            <img
              className="h-[90px] w-fit lg:ml-8 lefting heightof sethi shapur"
              src={sha}
              alt="3"
            />
          </div>
        </div>

        <div className=" ">
          <div className="flex  gap-6 ">
            <img className="h-[91px] w-fit heightof sethi" src={ko} alt="4" />
            <img
              className="h-[78px] w-fit heightof sethi"
              src={godrej1}
              alt="5"
            />
          </div>
        </div>

        <div className="">
          <div className="flex  gap-6 ">
            <img
              className="h-[90px] w-fit heightof sethi"
              src={lodha1}
              alt="6"
            />
            {/* <img className="h-[85px] w-fit "  src={vtp} alt="1" /> */}
          </div>
        </div>

        <div className=" ">
          <div className="flex  gap-6 ">
            {!isMobileView && (
              <img className="h-[91px] w-fit heightof sethi" src={ko} alt="7" />
            )}
            <img
              className="h-[70px] w-fit ml-12 mt-3 adjust heightinn sethi sahil"
              src={sahill}
              alt="8"
            />
          </div>
        </div>

        <div className=" ">
          <div className="flex   ">
            <img
              className="h-[96px] w-fit pr-2 heightof sethi"
              src={lux1}
              alt="9"
            />
            <img
              className="h-[82px] w-fit heightof sethi"
              src={godrej1}
              alt="10"
            />
          </div>
        </div>
      </Slider>
    </div>
  );
};

export default Logo;
