import React from 'react';
import './Footer.css'; // Make sure to import your CSS file

const Footer = () => {
  return (
    <div>
    <div className='footer'>
      <div className='footer-section'>
        <h2 className='orange-text'>Company</h2>
        <p className='gray-text'>Abouts Us</p>
        <p className='gray-text'>Blog</p>
        <p className='gray-text'>Career</p>
        <p className='gray-text'>Contact Us</p>
      </div>
      <div className='foot footer-section'>
        <h2 className='orange-text'>Property Center</h2>
        <p className='gray-text'>Commercial Property For Investment</p>
        <p className='gray-text'>Commercial Property For Leasing</p>
        <p className='gray-text'>Commercial Property For Outright</p>
        <p className='gray-text'>Commercial Property For Estate</p>
      </div>
      <div className='sectionfore footer-section'>
        <h2 className='orange-text'>Quick Links</h2>
        <p className='gray-text'>Commercial Property</p>
        <p className='gray-text'>Residential Property</p>
        <p className='gray-text'>Property Management Services</p>
      </div>
      <div className='footer-section'>
        <h2 className='orange-text'>Legal</h2>
        <p className='gray-text'>Privacy Policy</p>
      </div>
     
    </div>
     <div  className='center'>
     <p className='texting'>&copy; 2023 Copyright Brickfolio Pvt. Ltd.. All Rights Reserved.</p>
     </div>
     </div>
  );
};

export default Footer;
