import React from 'react'
import Navbar from '../common/Navbar'


const Second = () => {
  return (
    <div>
        <Navbar/>
      Second
    </div>
  )
}

export default Second
