import React, { useState } from "react";
import drop from "../assets/drop.webp";
import "./CustomDropdown.css";

const CustomDropdown = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState("Residential");
  const [commercialSubOption, setCommercialSubOption] = useState(null);

  const handleOptionChange = (option) => {
    setSelectedOption(option);
    if (option === "Commercial") {
      setCommercialSubOption(null);
      setIsOpen(true);
    } else {
      setCommercialSubOption(null);
      setIsOpen(false);
    }
  };

  const handleCommercialSubOptionChange = (subOption) => {
    setCommercialSubOption(subOption);
    setIsOpen(false);
  };

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const getSearchText = () => {
    if (selectedOption === "Commercial" && commercialSubOption) {
      return `${commercialSubOption}`;
    }
    return selectedOption;
  };

  return (
    <div className={`custom-dropdown ${isOpen ? "open" : ""}`}>
      {isOpen && (
        <div className="dropdown-options">
          <label>
            <input
              type="radio"
              value="Residential"
              checked={selectedOption === "Residential"}
              onChange={() => handleOptionChange("Residential")}
            />
            Residential
          </label>
          <label>
            <input
              type="radio"
              value="Commercial"
              checked={selectedOption === "Commercial"}
              onChange={() => handleOptionChange("Commercial")}
            />
            Commercial
          </label>
          {selectedOption === "Commercial" && (
            <div className="unit-type-heading">
              <h3 className="headingg">Unit Type</h3>
            </div>
          )}
          {selectedOption === "Commercial" && (
            <div className="commercial-sub-options">
              <label>
                <input
                  type="radio"
                  value="Leasing"
                  checked={commercialSubOption === "Leasing"}
                  onChange={() => handleCommercialSubOptionChange("Leasing")}
                />
                Leasing
              </label>
              <label>
                <input
                  type="radio"
                  value="Preleasing"
                  checked={commercialSubOption === "Pre-Leasing"}
                  onChange={() => handleCommercialSubOptionChange("Preleasing")}
                />
                Pre-Leasing
              </label>
              <label>
                <input
                  type="radio"
                  value="Outright"
                  checked={commercialSubOption === "Outright"}
                  onChange={() => handleCommercialSubOptionChange("Outright")}
                />
                Outright
              </label>
            </div>
          )}
        </div>
      )}
      <button className="dropdown-button" onClick={handleToggle}>
        {getSearchText()}
        <img className="dropdown-icon" src={drop} alt="Custom Icon" />
      </button>
    </div>
  );
};

export default CustomDropdown;
