import React, { useState, useEffect, useRef } from "react";
import Slider from "react-slick";
import LazyLoad from "react-lazyload";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Testimonials.css";
import ri from "../assets/ri.webp";
import le from "../assets/le.webp";

const Testimonials = () => {
  const [currentVideo, setCurrentVideo] = useState(0);
  const sliderRef = useRef(null);
  const youtubePlayers = useRef([]);
  const videoNumbers = [
    "99fNzK8UYf0",
    "FGjHJT-DyFA",
    "bObgLMpPxZw",
    "SQfaoFuZsck",
  ];

  useEffect(() => {
    const initializeYouTubePlayers = () => {
      videoNumbers.forEach((videoId, index) => {
        const player = new window.YT.Player(`youtube-player-${index}`, {
          height: "315",
          width: "560",
          videoId: videoId,
          events: {
            onReady: onPlayerReady,
            onStateChange: onPlayerStateChange,
          },
        });
        youtubePlayers.current.push(player);
      });
    };

    const handleYouTubeAPIReady = () => {
      initializeYouTubePlayers();
    };

    if (!window.YT || !window.YT.Player) {
      const tag = document.createElement("script");
      tag.src = "https://www.youtube.com/iframe_api";
      const firstScriptTag = document.getElementsByTagName("script")[0];
      firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

      window.onYouTubeIframeAPIReady = handleYouTubeAPIReady;
    } else {
      initializeYouTubePlayers();
    }

    return () => {
      window.onYouTubeIframeAPIReady = null;
    };
  }, [videoNumbers]);

  const onPlayerReady = (event) => {
    // Handle player ready if needed
  };

  const onPlayerStateChange = (event) => {
    // Handle player state change if needed
  };

  const PrevArrow = (props) => (
    <button
      {...props}
      className="slick-prevv"
      aria-label="Previous"
      type="button"
      onClick={() => {
        if (isMobileDevice() && sliderRef.current) {
          sliderRef.current.slickNext(); // Use slickNext to move left on click
        }
      }}
    >
      <img src={ri} className="first-arrow" alt="Previous" />
    </button>
  );

  const NextArrow = (props) => (
    <button
      {...props}
      className="slick-nextt"
      aria-label="Next"
      type="button"
      onClick={() => {
        if (isMobileDevice() && sliderRef.current) {
          sliderRef.current.slickPrev(); // Use slickPrev to move right on click
        }
      }}
    >
      <img src={le} className="second-arrow" alt="Next" />
    </button>
  );

  const isMobileDevice = () => {
    return window.innerWidth <= 768; // Adjust the width as needed for mobile devices
  };

  const settings = {
    dots: true,
    dotsClass: "slick-dots horizontal-dots",
    infinite: false,
    speed: 500,
    touchMove: true,
    draggable: !isMobileDevice(), // Disable dragging for mobile devices
    swipe: true,
    touchThreshold: 10,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipeToSlide: true,
    initialSlide: 0,
    autoplay: !isMobileDevice(),
    autoplaySpeed: 4000,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    afterChange: (index) => {
      setCurrentVideo(index);
      pauseYouTubeVideos();

      if (index === videoNumbers.length - 1) {
        setTimeout(() => {
          if (sliderRef.current) {
            setCurrentVideo(0);
            sliderRef.current.slickGoTo(0, false);
          }
        }, settings.autoplaySpeed / 2);
      }
    },
  };

  const pauseYouTubeVideos = () => {
    youtubePlayers.current.forEach((player) => {
      if (player && player.pauseVideo) {
        player.pauseVideo();
      }
    });
  };

  const renderVideos = () => {
    return videoNumbers.map((videoId, index) => (
      <div
        className="video-slide"
        key={index}
        style={{
          display: currentVideo === index ? "block" : "none",
        }}
      >
        <LazyLoad height={200} once>
          <div id={`youtube-player-${index}`}>
            {currentVideo === index && (
              <iframe
                width="560"
                height="315"
                src={`https://www.youtube.com/embed/${videoId}`}
                title={`YouTube Video ${index}`}
                frameBorder="0"
                allowFullScreen
              ></iframe>
            )}
          </div>
        </LazyLoad>
      </div>
    ));
  };

  return (
    <div className="testimonials-container">
      <h2 className="text-2xl daschi achi md:text-4xl Atopb font-semibold text-gray-600 text-center mt-10 lg:mt-20">
        <span style={{ color: "#B6ADAD" }}>Hear It From </span>
        <span style={{ color: "#F2833E" }}>Our Clients</span>
      </h2>

      <div className="grayBackground">
        <Slider {...settings} ref={sliderRef}>
          {renderVideos()}
        </Slider>
      </div>
    </div>
  );
};

export default Testimonials;
