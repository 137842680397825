import React from 'react'

const Try = () => {
  return (
    <div>
      Try
    </div>
  )
}

export default Try
