import React, { useState, useRef, useEffect } from "react";
import card1 from "../assets/cards1.webp";
import card21 from "../assets/card21.webp";
import card31 from "../assets/card31.webp";
import "./Owl.css";

const Try = () => {
  const containerRef = useRef(null);
  const [isPaused, setIsPaused] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);

  useEffect(() => {
    const handleMove = (e) => {
      if (!isPaused || !containerRef.current) return;
      const x =
        e.type === "mousemove"
          ? e.pageX - containerRef.current.offsetLeft
          : e.touches[0].pageX - containerRef.current.offsetLeft;
      const walk = (x - startX) * 3; // Adjust the scrolling speed
      containerRef.current.scrollLeft = scrollLeft - walk;
    };

    document.addEventListener("mousemove", handleMove);
    document.addEventListener("touchmove", handleMove);

    return () => {
      document.removeEventListener("mousemove", handleMove);
      document.removeEventListener("touchmove", handleMove);
    };
  }, [isPaused, startX, scrollLeft]);

  const handleStart = (e) => {
    if (containerRef.current) {
      setIsPaused(true);
      setStartX(
        e.type === "mousedown"
          ? e.pageX - containerRef.current.offsetLeft
          : e.touches[0].pageX - containerRef.current.offsetLeft
      );
      setScrollLeft(containerRef.current.scrollLeft);
    }
  };

  const handleEnd = () => {
    setIsPaused(false);
  };

  const isTablet = window.innerWidth >= 768 && window.innerWidth <= 1024;

  return (
    <div>
      <h1 className="text-2xl md:text-4xl  topb font-semibold text-gray-600 text-center mt-10 md:mt-20 deskp">
        <span style={{ color: "#B6ADAD" }}>Elevate Your Lifestyle with</span>
        <span style={{ color: "#F2833E" }}> Trending Projects</span>
      </h1>
      <div
        className={`relative flex overflow-x-hidden ${
          isPaused ? "paused" : ""
        }`}
        onMouseDown={handleStart}
        onMouseUp={handleEnd}
        onTouchStart={handleStart}
        onTouchEnd={handleEnd}
        onTouchCancel={handleEnd}
        onMouseEnter={() => setIsPaused(true)}
        onMouseLeave={() => setIsPaused(false)}
        ref={containerRef}
      >
        <div className="py-12 animate-marquee whitespace-nowrap">
          <img className="mx-4 set" src={card1} alt="1" />
          <img className="mx-4 set" src={card21} alt="2" />
          <img className="mx-4 set" src={card31} alt="3" />
          {window.innerWidth > 768 ? null : (
            <img className="mx-4 set" src={card21} alt="4" />
          )}
          {isTablet && <img className="mx-4 set" src={card21} alt="5" />}
        </div>

        <div
          className={`absolute top-0 py-12 animate-marquee2 whitespace-nowrap ${
            isPaused ? "paused" : ""
          }`}
        >
          <div
            onMouseEnter={() => setIsPaused(true)}
            onMouseLeave={() => setIsPaused(false)}
          >
            <img className="mx-4 set onecard ms" src={card1} alt="6" />
            <img className="mx-4 set" src={card21} alt="7" />
            <img className="mx-4 set" src={card31} alt="8" />
            <img className="mx-4 set" src={card1} alt="9" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Try;
