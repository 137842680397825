import React from 'react'
import Home from './Home'
import Card from './Card'
import Feature from './Feature'
import Counter from './Counter'
import Achivments from './Achivments'
import Testimonials from './Testimonials'
import News from './News'
import Ldemo from './Ldemo'



const AllHomeComponents = () => {
  return (
    <div>
    <Home/>
    <Card/>
    <Feature/>
    <Counter/>
    <Ldemo/>
    <Achivments/>
    <Testimonials/>
    <News/>  
 </div>
  )
}

export default AllHomeComponents
